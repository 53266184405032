<template>
    <!-- 行业动态 -->
    <div class="dynamic">
        <div class="dy_title flex JustifyContentSB">
            <div class="titte_before">行业动态</div>
            <div class="dy_more " @click="dynamicMore" v-if="showMore == 'show'">
                更多
                <img src="@/assets/images/school/icon_right.png" alt="">
            </div>
        </div>
        <div class="dynamic_content flex JustifyContentSB">
            <div class="con flexC JustifyContentSB" v-for="(val,idx) in dynamicList" :key="idx" @click="littleSwiperClick(val.link)">
                <div class="img">
                    <img :src="val.img" alt="" srcset="">
                </div>
                <div class="title twoEllipsis">{{val.title}}</div>
                <div class="district twoEllipsis">{{val.district}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        dynamicList:Array,
        showMore:String
    },
    data(){
        return{
            
        }
    },
    methods:{
        // 查看更多行业动态
        dynamicMore(){
            this.$router.push('/industryDynamics');
        },
        littleSwiperClick (url) {
            if (url) {
                window.open(url, 'blank')
            }
        },
    }
}
</script>

<style lang='scss' scoped>
@media screen and (min-width: 1560px){
    .dynamic{
        padding: 0 16%;
        margin: 62px 0px;
        .dy_title{
            width: 100%;
            margin-left:10px;
            margin-bottom:10px;
        }
        .titte_before{
            font-size: 26px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #333333;
            line-height: 39px;
        }
        .titte_before::before{
            content: '';
            display: inline-block;
            width: 4px;
            height: 23px;
            background: #EA5A41;
            position: relative;
            top:2px;
            left:-10px
        }
        .dy_more{
            font-size: 14px;
            font-family: SourceHanSansCN-Normal, SourceHanSansCN;
            font-weight: 400;
            color: #999999;
            margin-right: 20px;
            cursor: pointer;
            img{
                width: 6px;
                height: 10px;
                margin-bottom: 4px;
            }
        }
       
        .dynamic_content{
            width: 100%;
            .con{
                width: 24%;
                .img{
                    // width: 95%;
                    margin-right:10px;
                    img{
                        width: 100%;
                    }
                }
                .title{
                    width: 93%;
                    font-size: 16px;
                    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                    font-weight: bold;
                    color: #333333;
                    line-height: 18px;
                    padding:16px 5px;
                }
                .district{
                    width: 93%;
                    margin-right:10px;
                    font-size: 12px;
                    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
                    font-weight: 400;
                    color: #999999;
                    line-height: 18px;
                    padding:0px 4px;
                }
            }
            

        }
    }
}
@media screen and (max-width: 1560px){
    .dynamic{
        padding: 0 10%;
        margin: 62px 0px;
        .dy_title{
            width: 100%;
            margin-left:10px;
            margin-bottom:10px;
        }
        .titte_before{
            font-size: 26px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #333333;
            line-height: 39px;
        }
        .titte_before::before{
            content: '';
            display: inline-block;
            width: 4px;
            height: 23px;
            background: #EA5A41;
            position: relative;
            top:2px;
            left:-10px
        }
        .dy_more{
            font-size: 14px;
            font-family: SourceHanSansCN-Normal, SourceHanSansCN;
            font-weight: 400;
            color: #999999;
            margin-right: 20px;
            cursor: pointer;
            img{
                width: 6px;
                height: 10px;
                margin-bottom: 4px;
            }
        }
       
        .dynamic_content{
            width: 100%;
            .con{
                width: 24%;
                .img{
                    // width: 95%;
                    margin-right:10px;
                    img{
                        width: 100%;
                    }
                }
                .title{
                    width: 93%;
                    font-size: 16px;
                    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                    font-weight: bold;
                    color: #333333;
                    line-height: 18px;
                    padding:16px 5px;
                }
                .district{
                    width: 93%;
                    margin-right:10px;
                    font-size: 12px;
                    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
                    font-weight: 400;
                    color: #999999;
                    line-height: 18px;
                    padding:0px 4px;
                }
            }
            

        }
    }
}

</style>