<template>
    <div class="videoModule">
        <div class="wrapper">
            <h2>{{iframeForm.firstTitle}}</h2>
            <h3>{{iframeForm.secondTitle}}</h3>
            <div class="swipe-main">
                <el-carousel
                height="387px"
                :interval="5000"
                :autoplay="false"
                arrow="always"
                indicator-position="none"
                @change="getIndex"
                >
                    <el-carousel-item
                    v-for="(item, index) in iframeList"
                    :key="index"
                    >
                    <div class="swipe-box">
                        <div v-show="!isPlay" class="overlay">
                        <div class="wrapper">
                            <div class="flex icon JustifyContentC AlignItemsC">
                            <van-icon
                                name="play"
                                color="#fff"
                                @click="playVideo"
                            />
                            </div>
                        </div>
                        </div>
                        <video
                        :ref="'videoPlayer' + index"
                        width="100%"
                        height="100%"
                        :src="item.src_mp4"
                        :controls="isPlay"
                        :poster="item.img_src"
                        />
                    </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <div class="swipe-text">
          <div class="flexC JustifyContentC">
            <span>{{ title }}</span>
            <p>{{ text }}</p>
          </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        iframeList:Array,
        iframeForm:Object
    },
    data(){
        return{
            activeName: "second",
            title: "",
            text: "",
            isPlay: false, //播放状态
            nowIndex: 0,
        }
    },
    mounted() {
        this.initData(0);
        //初始监听第一个视频
        this.listenVideo();
    },
    methods:{
        initData(index) {
            let { title, text } = this.iframeList.find((v, i) => {
                if (i === index) {
                return v;
                }
            });
            this.title = title;
            this.text = text;
        },
         //获取走马灯的索引
        getIndex(nowIndex, oldIndex) {
            // console.log("nowIndex, oldIndex", nowIndex, oldIndex);
            //用户可能在上个视频未播放完毕就切换
            //此时需要暂停上个视频并把时间归零，否则影响对当前视频的监听逻辑
            let myVideo = this.$refs["videoPlayer" + oldIndex];
            // console.log("myVideo", myVideo);
            myVideo[0].pause();
            this.nowIndex = nowIndex;
            this.initData(nowIndex);
            this.listenVideo();
        },
        // /监听视频的播放、暂停、播放完毕等事件
        listenVideo() {
        //注意：这样获取的dom是一个数组，必须加上[0]索引才能正确的添加事件
            let myVideo = this.$refs["videoPlayer" + this.nowIndex];
            //监听播放
            myVideo[0].addEventListener("play", () => {
                this.autoplay = false;
                this.isPlay = true;
        });
        // //监听暂停
        // myVideo[0].addEventListener("pause", () => {
        //   this.autoplay = true;
        //   this.isPlay = false;
        // });
        // //监听播放完毕
        // myVideo[0].addEventListener("ended", () => {
        //   //时间归零
        //   myVideo[0].currentTime = 0;
        //   this.autoplay = true;
        //   this.isPlay = false;
        // });
        },
        //播放视频
        playVideo() {
            //注意：这样获取的dom是一个数组，必须加上[0]索引才能正确的添加事件
            let myVideo = this.$refs["videoPlayer" + this.nowIndex];
            myVideo[0].play();
        },
    }
}
</script>

<style lang='scss' scoped>
    @media screen and (min-width: 1560px){
        .videoModule{
            width: 100%;
            // margin-bottom: 20px;
            .wrapper{
                width: 100%;
                text-align: center;
                box-sizing: border-box;
                padding: 0 16%;
                 h2{
                    margin-top: 70px;
                    font-size: 40px;
                    font-weight: 500;
                    color: #3a3e4c;
                    line-height: 60px;
                    letter-spacing: 1px;
                    text-align: center;
                }
                h3{
                    font-size: 24px;
                    font-weight: 400;
                    color: #8c8c95;
                    line-height: 36px;
                    letter-spacing: 1px;
                    text-align: center;
                }
            }
            
        }
    }
    @media screen and (max-width: 1560px){
        .videoModule{
            width: 100%;
            // margin-bottom: 20px;
            .wrapper{
                width: 100%;
                text-align: center;
                box-sizing: border-box;
                padding: 0 10%;
                 h2{
                    margin-top: 70px;
                    font-size: 40px;
                    font-weight: 500;
                    color: #3a3e4c;
                    line-height: 60px;
                    letter-spacing: 1px;
                    text-align: center;
                }
                h3{
                    font-size: 24px;
                    font-weight: 400;
                    color: #8c8c95;
                    line-height: 36px;
                    letter-spacing: 1px;
                    text-align: center;
                }
            }
        }
        
    }
    .swipe-main {
      margin-top: 70px;
      ::v-deep {
        .el-carousel__container {
          button {
			  width: 65px;
			  height: 65px;
            color: #e6e6e6;
            font-size: 65px;
            background-color: #00000000;
          }
        }
      }
    }
    .swipe-box {
      width: 688px;
      height: 387px;
      margin: 0 auto;
      .overlay {
        position: absolute;
        width: 688px;
        height: 387px;
        background: rgba(0, 0, 0, 0.45);
        box-shadow: 0px 0px 10px 0px #cccccc;
        border-radius: 6px;
        z-index: 99;
      }
      .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        .icon {
          width: 120px;
          height: 120px;
          font-size: 80px;
          border-radius: 50%;
          background: rgba(0, 0, 0, 0.3);
        }
        img {
          width: 120px;
          height: 120px;
        }
      }
      .block {
        width: 120px;
        height: 120px;
        background-color: #ccccccff;
        border-radius: 50%;
      }
      img {
        width: 688px;
        height: 387px;
        margin: 0 auto;
      }
    }
    .swipe-text {
        height: 261px;
        margin-top: -40px;
        background: #fff4f4;
        div {
        width: 688px;
        height: 261px;
        margin: 0 auto;
        }
        span {
        font-size: 32px;

        font-weight: 500;
        color: #3a3e4c;
        line-height: 78px;
        letter-spacing: 1px;
        }
        p {
        font-size: 20px;

        font-weight: 400;
        color: #3a3e4c;
        line-height: 30px;
        letter-spacing: 1px;
        }
    }
</style>
