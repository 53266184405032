<template>
    <div class="category ">
        <div class="categoty_con" v-for="(val,inx) in categoryList" :key="inx">
            <div class="categoty_left" :style="{background: val.color}">
                <div class="title">{{val.title}}</div>
                <div class="line"></div>
                <div class="marks">{{val.mark}}</div>
                <div class="img">
                    <img :src="val.img" alt="">
                </div>
                
            </div>
            <div class="categoty_right">
                <div class="box-classifyBox">
                    <div class="classifyBox-left">
                        <div 
                            v-for="(item, index) in val.tabList"
                            @click="selFirstClass(inx,index,item.name)"
                            class='classifyItem'
                            :class="{'classifyItem-active':item.color}"
                            :key="index">{{item.name}}</div>
                    </div>
                    <div class="classifyBox-right" @click="dynamicMore(val.idx,val.title)">
                         更多
                        <img src="@/assets/images/school/icon_right.png" alt="">
                    </div>
                </div>
                <div class="mainListBox">
                    <div class="card" v-for="(item,index) in val.conList" :key="index" @click="toCourseDetails(item)">
                        <img :src="item.thumbnail" alt="">
                        <div class="courseName">{{item.name}}</div>
                        <div class="bottomInfo">
                            <div class="time">课时数：{{item.lessonCnt}}</div>
                            <div class="learnTimes">学习人数：{{item.learnCnt}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    props:{
        categoryList:Array,
        courseData:Array
    },
    data(){
        return{

            firstClassifyIndex:0,
        }
    },
    methods:{
        selFirstClass (idx,index,item) {
            this.getCourseLists(idx,index,item)
        },
        // 获取家政服务类课程
        getCourseLists(idx,index,item){
            this.categoryList[idx].tabList.forEach(v=>{
                return v.color = false;
            })
            this.categoryList[idx].tabList[index].color = true;
            this.categoryList[idx].conList =[];
            let list = JSON.parse(JSON.stringify(this.categoryList[idx].tabList))
            list.splice(0,1);
            if(item=='全部'){
                let listChildrenIds = [];
                list.forEach((val)=>{
                    val.children.map(cItem=>{
                        listChildrenIds.push(cItem.id)
                    })
                })
                this.courseData.forEach(cItem => {
                    listChildrenIds.forEach(val=>{
                        if (val == cItem.id) {
                            this.categoryList[idx].conList.push(cItem)
                        }
                    })
                })
            }else{
                let listChildrenIds = [];
                list.forEach((val)=>{
                    if(item == val.name){
                        val.children.map(cItem=>{
                            listChildrenIds.push(cItem.id)
                        })
                    }
                })
                this.courseData.forEach(cItem => {
                    listChildrenIds.forEach(val=>{
                        if (val == cItem.id) {
                            this.categoryList[idx].conList.push(cItem)
                        }
                    })
                })
            }
        },
        // 课程中心
        dynamicMore(idx,name){
            // console.log(idx,name);
            this.$router.push(
                {
                    path:"/courseCenter",
                    query:{
                        firstIndex:idx,
                        name:name
                    }
                }
            )
        },
        toCourseDetails (item) {
            localStorage.setItem('courseDetailsData', JSON.stringify(item))
            this.$router.push({name: 'courseDetails'})
        },
    }
}
</script>

<style lang='scss' scoped>
@media screen and (min-width: 1560px){
    .category{
        // margin:20px 0px 62px;
        padding: 20px 16%;
    }
    .categoty_con{
        display: flex;
        margin-bottom: 20px;
        .categoty_left{
            position: relative;
            display: flex;
            flex-direction: column;
            width: 305px;
            height: 582px;
            .title{
                text-align: center;
                margin: 20px 0px 0px;
                font-size: 34px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 51px;
            }
            .line{
                width: 20px;
                height: 2px;
                background: #FFFFFF;
                margin-left: 130px;
            }
            .marks{
                width: 100%;
                text-align: center;
                font-size: 16px;
                font-family: SourceHanSansCN-Normal, SourceHanSansCN;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 54px;
            }
            .img{
                position: absolute;
                left: 0px;
                bottom: 0px;
                width: 305px;
                // height: 456px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .categoty_right{
        width: calc( 100% - 305px);
        background: #FFFFFF;
        height: 582px;
        overflow-y: hidden;
    }
    .box-classifyBox {
        background: #FFFFFF;
        padding: 12px;
        display: flex;
        justify-content: space-between;
        .classifyBox-right {
            width: 50px;
            font-size: 14px;
            font-family: SourceHanSansCN-Normal, SourceHanSansCN;
            font-weight: 400;
            color: #999999;
            line-height: 50px;
            cursor: pointer;
            padding-right:15px;
            img{
                width: 6px;
                height: 10px;
                margin-bottom: 4px;
            }
        }
        .classifyBox-left {
            // display: flex;
            width: calc(100% - 50px);
            overflow: hidden;
            height: 44px;
            .classifyItem {
                padding: 7px 13px;
                float: left;
                margin: 10px 0 0 12px;
                font-size: 14px;
                border-radius: 30px;
                cursor: pointer;
            }
            .classifyItem:hover {
                color: #EA5A41;
            }
            .classifyItem-active {
                background: #EA5A41;
                color: white;
            }
            .classifyItem-active:hover {
                color: white;
            }
        }
    }
    .mainListBox {
        padding: 0px 23px;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        .card {
            width: 30%;
            background: #FFFFFF;
            border: 1px solid #F2F2F2;
            flex-shrink: 0;
            margin-bottom: 30px;
            margin-right: 3%;
            img {
                width: 100%;
                // height: 118px;
            }
            .courseName {
                padding: 0 10px;
                font-size: 14px;
                font-weight: bold;
                margin-top: 12px;
            }
            .bottomInfo {
                padding: 10px;
                display: flex;
                justify-content: space-between;
                font-size: 9px;
                color: #999999;
            }
        }
    }
}

@media screen  and (max-width: 1360px){
    .category{
        // margin:20px 0px 62px;
        padding: 20px 10%;
    }
    .categoty_con{
        display: flex;
        margin-bottom: 20px;
        .categoty_left{
            position: relative;
            display: flex;
            flex-direction: column;
            width: 224px;
            height: 490px;
            .title{
                text-align: center;
                margin: 20px 0px 0px;
                font-size: 34px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 51px;
            }
            .line{
                width: 20px;
                height: 2px;
                background: #FFFFFF;
                margin-left: 100px;
            }
            .marks{
                width: 100%;
                text-align: center;
                font-size: 16px;
                font-family: SourceHanSansCN-Normal, SourceHanSansCN;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 54px;
            }
            .img{
                position: absolute;
                left: 0px;
                bottom: 0px;
                width: 224px;
                // height: 416px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .categoty_right{
        width: calc( 100% - 224px);
        height: 490px;
        // width: calc( 100% - 305px);
        // height: 582px;
        background: #FFFFFF;
        overflow-y: hidden;
    }
    .box-classifyBox {
        background: #FFFFFF;
        padding: 12px;
        display: flex;
        justify-content: space-between;
        .classifyBox-right {
            width: 50px;
            font-size: 14px;
            font-family: SourceHanSansCN-Normal, SourceHanSansCN;
            font-weight: 400;
            color: #999999;
            line-height: 50px;
            cursor: pointer;
            padding-right:15px;
            img{
                width: 6px;
                height: 10px;
                margin-bottom: 4px;
            }
        }
        .classifyBox-left {
            // display: flex;
            width: calc(100% - 50px);
            overflow: hidden;
            height: 44px;
            .classifyItem {
                padding: 7px 13px;
                float: left;
                margin: 10px 0 0 12px;
                font-size: 14px;
                border-radius: 30px;
                cursor: pointer;
            }
            .classifyItem:hover {
                color: #EA5A41;
            }
            .classifyItem-active {
                background: #EA5A41;
                color: white;
            }
            .classifyItem-active:hover {
                color: white;
            }
        }
    }
    .mainListBox {
        padding: 0px 0px 0px 23px;
        display: flex;
        flex-wrap: wrap;
        .card {
            width: 30%;
            background: #FFFFFF;
            border: 1px solid #F2F2F2;
            flex-shrink: 0;
            margin-bottom: 30px;
            margin-right: 3%;
            img {
                width: 100%;
                // height: 118px;
            }
            .courseName {
                padding: 0 10px;
                font-size: 14px;
                font-weight: bold;
                margin-top: 12px;
            }
            .bottomInfo {
                padding: 10px;
                display: flex;
                justify-content: space-between;
                font-size: 9px;
                color: #999999;
            }
        }
    }
}
@media screen and (min-width: 1360px)and (max-width: 1560px){
    .category{
        // margin:20px 0px 62px;
        padding: 20px 10%;
    }
    .categoty_con{
        display: flex;
        margin-bottom: 20px;
        .categoty_left{
            position: relative;
            display: flex;
            flex-direction: column;
            width: 278px;
            height: 541px;
            .title{
                text-align: center;
                margin: 20px 0px 0px;
                font-size: 34px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 51px;
            }
            .line{
                width: 20px;
                height: 2px;
                background: #FFFFFF;
                margin-left: 130px;
            }
            .marks{
                width: 100%;
                text-align: center;
                font-size: 16px;
                font-family: SourceHanSansCN-Normal, SourceHanSansCN;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 54px;
            }
            .img{
                position: absolute;
                left: 0px;
                bottom: 0px;
                width: 278px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .categoty_right{
        width: calc( 100% - 278px);
        height: 541px;
        // width: calc( 100% - 305px);
        // height: 582px;
        background: #FFFFFF;
        overflow-y: hidden;
    }
    .box-classifyBox {
        background: #FFFFFF;
        padding: 12px;
        display: flex;
        justify-content: space-between;
        .classifyBox-right {
            width: 50px;
            font-size: 14px;
            font-family: SourceHanSansCN-Normal, SourceHanSansCN;
            font-weight: 400;
            color: #999999;
            line-height: 50px;
            cursor: pointer;
            padding-right:15px;
            img{
                width: 6px;
                height: 10px;
                margin-bottom: 4px;
            }
        }
        .classifyBox-left {
            // display: flex;
            width: calc(100% - 50px);
            overflow: hidden;
            height: 44px;
            .classifyItem {
                padding: 7px 13px;
                float: left;
                margin: 10px 0 0 12px;
                font-size: 14px;
                border-radius: 30px;
                cursor: pointer;
            }
            .classifyItem:hover {
                color: #EA5A41;
            }
            .classifyItem-active {
                background: #EA5A41;
                color: white;
            }
            .classifyItem-active:hover {
                color: white;
            }
        }
    }
    .mainListBox {
        padding: 0px 0px 0px 23px;
        display: flex;
        flex-wrap: wrap;
        .card {
            width: 30%;
            background: #FFFFFF;
            border: 1px solid #F2F2F2;
            flex-shrink: 0;
            margin-bottom: 30px;
            margin-right: 3%;
            img {
                width: 100%;
                // height: 118px;
            }
            .courseName {
                padding: 0 10px;
                font-size: 14px;
                font-weight: bold;
                margin-top: 12px;
            }
            .bottomInfo {
                padding: 10px;
                display: flex;
                justify-content: space-between;
                font-size: 9px;
                color: #999999;
            }
        }
    }
}
</style>